import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Logo } from "./utils"
import Navlinks from "./navigation-list"
import Subscribe from "../components/footercontact"
import {
    FooterLinksQuery,
    FooterLinksQuery_site_siteMetadata_footerLinks,
} from "./__generated__/FooterLinksQuery"

export default function() {
    const query = useStaticQuery<FooterLinksQuery>(graphql`
        query FooterLinksQuery {
            site {
                siteMetadata {
                    title
                    footerLinks {
                        name
                        url
                    }
                }
            }
        }
    `)

    const footerLinks = query.site.siteMetadata.footerLinks.map((item, _) => (
        <>
            <ListItem data={item} key={`footer-n-l-${_}`} />
            <br></br>
        </>
    ))

    return (
        <footer className="footer bg-bgalt2 lg:py-12 relative">
            <div className="container mx-auto text-center">
                <div className="sm:flex justify-center my-8 py-2">
                    {/* <Link to="/" title={query.site.siteMetadata.title}>
                        <Logo className="w-64 lg:mt-24" />
                        <h2>ニュースサービス日経銀座</h2>
                    </Link> */}
                    {/* <Subscribe /> */}
                    <div className="w-full sm:w-1/3 footer-links animated-link-parent">
                        <h5>メニュー</h5>
                        <div className="menu">
                            <Navlinks
                                className="sm:flex items-center justify-center flex-col"
                                withThemeSwitch={false}
                            />
                            <ul>{footerLinks}</ul>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3 footer-links animated-link-parent items-center justify-center flex-col ">
                        <h5>外部リンク</h5>
                        <div className="menu">
                            <a href="https://www.poke.co.jp/?atid=1&asid=1354">
                                ポケカル
                            </a>{" "}
                            <br />
                            <a href="https://www.nikkeipr.co.jp/npos/">
                                NPOS
                            </a>{" "}
                            <br />
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf0liR_MuVi9eR-ulP3N_mpVu1hhECVwG7dt2JLH0nl-YMQig/viewform">
                                ニュースサービス日経でんき
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className="text-color-4 my-3 footer-links animated-link-parent"></div>
                <div className="text-color-4 my-3"></div> */}
                <p className="text-color-default text-sm">
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    {query.site.siteMetadata.title}{" "}
                </p>
            </div>
        </footer>
    )
}

const ListItem: React.FC<{
    data: FooterLinksQuery_site_siteMetadata_footerLinks
}> = ({ data }) => {
    return (
        <li className="inline-block mx-3 animated-link-parent">
            <Link to={data.url} title={data.name}>
                <span>{data.name}</span>
            </Link>
        </li>
    )
}
